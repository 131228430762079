export const CheckBox: React.FC<{ item: string; handleCheck: Function }> = (
    props
) => {
    return (
        <div className="form-check">
            <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                onChange={() => props.handleCheck(props.item)}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
                {props.item}
            </label>
        </div>
    );
};
