import { useState } from "react";
import { CheckBox } from "./Components/CheckBox";

export const Form = () => {
    const [output, setOutput] = useState<string[]>([]);
    const checkList: string[] = [
        "Ausdrucksfehler korrigiert",
        "Einheitlichkeit:",
        "Entsprechende Links gesetzt",
        "Fehlende Wörter eingefügt",
        "Fehlende Zeichen eingefügt",
        "Formatierungsfehler korrigiert",
        "Formulierung zum Zweck der Lesbarkeit angepasst",
        "Grammatik korrigiert",
        "Inhalt ergänzt",
        "Inhaltliche Fehler korrigiert",
        "Link korrigiert",
        "Rechtschreib- bzw. Tippfehler korrigiert",
        "Überflüssige Sätze entfernt",
        "Überflüssige Wörter entfernt",
        "Überflüssige Zeichen entfernt",
    ];

    const handleCheck = (item: string) => {
        if (output.includes(item)) {
            setOutput((prevItems) =>
                prevItems.filter((prevItem) => prevItem !== item)
            );
        } else {
            setOutput((prevItems) => [...prevItems, item]);
        }
    };

    const Boxes = checkList.map((item) => {
        return (
            <CheckBox
                key={checkList[checkList.indexOf(item)]}
                item={item}
                handleCheck={handleCheck}
            />
        );
    });

    const copyToClipboard = async () => {
        try {
            let textToCopy = output.join("; ");
            await navigator.clipboard.writeText(textToCopy);
        } catch (err) {
            console.error("Unable to copy to clipboard:", err);
        }
    };

    return (
        <div className="container d-flex flex-column align-items-center justify-content-center max-w-xs mt-5">
            <div style={{ width: 400 }}>{Boxes}</div>

            <div>
                <div
                    className="mt-5 border rounded p-3"
                    style={{ minWidth: 400 }}
                >
                    <p>{output.join("; ")}</p>
                </div>
                <button
                    type="button"
                    className="btn btn-outline-primary mt-2"
                    onClick={copyToClipboard}
                >
                    Copy
                </button>
            </div>
        </div>
    );
};
