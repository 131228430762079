import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Form } from "./Layouts/Homepage/Form";

function App() {
    return (
        <div className="App">
            <Form />
        </div>
    );
}

export default App;
